import React, { useState, useEffect } from "react"
// import { Link } from "gatsby"
// import * as msal from "@azure/msal-browser"
// import keys from "../config/config"
// import Layout from "../components/layout"
// import Seo from "../components/seo"
// import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
// import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

const SecondPage = () => {
  //   const [loggedIn, setLoggedIn] = useState(false)
  //   const msalConfig = {
  //     auth: {
  //       clientId: keys.msalConfig.clientId,
  //       authority: keys.msalConfig.authority,
  //       redirectUri: keys.msalConfig.redirectUri,
  //     },
  //   }

  //   const msalInstance = new msal.PublicClientApplication(msalConfig)
  //   useEffect(() => {
  //     handleSSOLogin()
  //   }, [])
  //   const handleSSOLogin = async () => {
  //     const loggedInCheck = localStorage.getItem("ms-login-info")
  //     console.log(loggedInCheck)
  //     const silentRequest = {
  //       scopes: ["User.Read"],
  //     }
  //     if (!loggedInCheck == null) {
  //       setLoggedIn(true)
  //     } else {
  //       try {
  //         const loginResponse = msalInstance.ssoSilent(silentRequest)
  //         localStorage.setItem("ms-login-info", JSON.stringify(loginResponse))
  //         setLoggedIn(true)
  //       } catch (err) {
  //         if (err) {
  //           try {
  //             const loginResponse = msalInstance.loginPopup(silentRequest)
  //             localStorage.setItem("ms-login-info", JSON.stringify(loginResponse))
  //             setLoggedIn(true)
  //           } catch (err) {
  //             // console.log(err);
  //           }
  //         } else {
  //           // handle error
  //           console.log(err)
  //         }
  //       }
  //     }
  //   }
  return (
    //     <Layout>
    //  {loggedIn && <h1>Hello0</h1>}
    <>
      <p>Hello world</p>
      {/* <button
        onClick={() => {
          fetch("http://localhost:5000/stage-login")
            .then(res => {
              console.log(res.body)
            })
            .catch(err => console.log(err))
        }}
      >
        Submit
      </button> */}
    </>
    //     </Layout>
  )
}

export default SecondPage
